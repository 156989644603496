import Button from "components/Button";
import React from "react";

const NotFoundPage = () => (
  // <Layout bodyClass="page-404">
  <div className="container spaced-100">
    <h1>404</h1>
    <p>
      We’re sorry, the page you are looking for could not be found or no longer
      exists.
    </p>
    <Button link="/" classes="blue">
      Return Home
    </Button>
  </div>
  // </Layout>
);

export default NotFoundPage;
